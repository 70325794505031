/* tslint:disable:no-console */

import {register} from 'register-service-worker';
import Cookies from 'js-cookie';
if (process.env.NODE_ENV === 'production') {
  register(`./service-worker.js`, {
    ready() {
      const version = process.env.PACKAGE_VERSION;
      const oldVersion: string = Cookies.get('version') || '0.0.0';
      const [major, _minor, _patch]: string[] = version.split('.');
      const [oldMajor, _oldMinor, _oldPatch]: string[] = oldVersion.split('.');

      //si version anterieur ou version non trouvé
      if (oldMajor !== major) {
        Cookies.set('version', version, {expires: 365});
        window.localStorage.clear();
        window.location.reload();
      }
    },
    registered() {
      console.log('Service worker has been registered. in app');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
